// components/SkiDetail/SkiDetail.jsx

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../context/AuthContext';
import { getSkiTests } from '../../../firebase/firestoreFunctions';
import CombinedConditionsHeatmap from './CombinedConditionsHeatmap/CombinedConditionsHeatmap';
import PerformanceChart from './PerformanceChart/PerformanceChart';
import SelectSeason from './PerformanceChart/SelectSeason';
import { RiInboxArchiveLine, RiInboxUnarchiveLine, RiEditLine, RiDeleteBinLine } from "react-icons/ri";

import { formatDate, getTimestamp, getSeason } from '../../../helpers/helpers';
import GrindHistory from '../../../components/GrindHistory/GrindHistory';

const SkiDetail = ({ ski, onDelete, onArchive, onUnarchive }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  // Chart data for THIS ski
  const [chartData, setChartData] = useState([]);

  // Season filter (performance chart only)
  const [selectedSeason, setSelectedSeason] = useState('');

  // For the performance chart's container width/scroll
  const [containerWidth, setContainerWidth] = useState('100%');
  const chartContainerRef = useRef(null);

  // Cache for test results (key = testId)
  const [testResultsCache, setTestResultsCache] = useState({});

  // Additional filters for performance chart
  const [selectedSnowType, setSelectedSnowType] = useState('');
  const [selectedTemperature, setSelectedTemperature] = useState('');

  // NEW: Toggle for the current grind and current season (applies to heatmap only)
  const [showCurrentGrind, setShowCurrentGrind] = useState(false);
  const [showCurrentSeason, setShowCurrentSeason] = useState(false);


  // Define possible sources and grain types
  const allSources = ['natural', 'artificial', 'mix'];
  const allSnowTypes = [
    'fresh',
    'fine_grained',
    'coarse_grained',
    'wet',
    'icy_conditions',
    'sugary_snow'
  ];

  // Build an array of every combination of source + grainType
  const allSnowCombos = useMemo(() => {
    const combos = [];
    allSources.forEach((source) => {
      allSnowTypes.forEach((grainType) => {
        combos.push({ source, grainType });
      });
    });
    return combos;
  }, [allSources, allSnowTypes]);

  // Score → category label (0 to 1 scale)
  const getPerformanceCategory = useCallback((avgScore) => {
    if (avgScore === null || avgScore === undefined) return 'Unknown';
    if (avgScore >= 0.85) return 'great';
    if (avgScore >= 0.65) return 'good';
    if (avgScore >= 0.45) return 'average';
    if (avgScore >= 0.25) return 'bad';
    return 'very_bad';
  }, []);

  // Handle season change
  const handleSeasonChange = useCallback((event) => {
    setSelectedSeason(event.target.value);
  }, []);

  // Custom tooltip for PerformanceChart
  const CustomTooltip = useCallback(
    ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
          <div className="custom-tooltip bg-background p-5 border border-sbtn shadow-xl rounded">
            <p className='font-semibold text-lg mb-2'>{`${data.rank}/${data.total}`}</p>
            <p className='text-sm'>{`${t('temperature')}: ${data.temp}°C`}</p>
            <p className='text-sm'>{`${t('location')}: ${data.location}`}</p>
            <p className="text-sm">
              {t('snow_source')}: {t(data.snowSource)}
            </p>
            <p className="text-sm">
              {t('snow_type')}: {t(data.snowType)}
            </p>
            <p className="text-sm">{`${t('test_date')}: ${new Date(label).toLocaleDateString()}`}</p>
          </div>
        );
      }
      return null;
    },
    [t]
  );

  // Gather the unique temperatures from all chartData
  const dynamicTemperatureList = useMemo(() => {
    const temps = [...new Set(chartData.map((data) => data.temp))];
    return temps.sort((a, b) => a - b);
  }, [chartData]);

  // --- Performance Chart: Filtered data (season / optional filters) ---
  const filteredChartData = useMemo(() => {
    return chartData.filter((data) => {
      const season = getSeason(data.testDate);
      const matchSeason = selectedSeason ? season === selectedSeason : true;
      const matchSnowType = selectedSnowType
        ? data.snowType === selectedSnowType
        : true;
      const matchTemp = selectedTemperature
        ? data.temp <= selectedTemperature
        : true;
      return matchSeason && matchSnowType && matchTemp;
    });
  }, [chartData, selectedSeason, selectedSnowType, selectedTemperature]);

  // --- Heatmap: If showCurrentGrind is true, filter out tests prior to current grind date ---
  const heatmapChartData = useMemo(() => {
    let filtered = chartData;

    // 1) Filter by grind date if showCurrentGrind
    if (showCurrentGrind) {
      const grindTs = getTimestamp(ski.grindDate);
      if (grindTs) {
        filtered = filtered.filter((d) => d.testDate >= grindTs);
      }
    }

    // 2) Filter by current season if showCurrentSeason
    if (showCurrentSeason) {
      const now = Date.now();
      const currentSeason = getSeason(now);
      // e.g. getSeason might return something like '2024-2025' or 'Summer 2025'
      filtered = filtered.filter((d) => getSeason(d.testDate) === currentSeason);
    }

    return filtered;
  }, [chartData, showCurrentGrind, showCurrentSeason, ski.grindDate]);


  // Build combinedPerformanceData for the heatmap from heatmapChartData
  const combinedPerformanceData = useMemo(() => {
    // Prepare a map for temperature x source x snowType
    const performanceMap = {};
    dynamicTemperatureList.forEach((temp) => {
      allSnowCombos.forEach(({ source, grainType }) => {
        const key = `${temp}___${source}___${grainType}`;
        performanceMap[key] = {
          temperature: temp,
          source,
          snowType: grainType,
          sumWeightedScore: 0,
          sumWeights: 0,
          testCount: 0,
        };
      });
    });

    // Aggregate over the (filtered) heatmap data
    heatmapChartData.forEach((data) => {
      // Skip if fewer than 4 skis in that test
      if (data.total < 4) return;

      const temp = data.temp;
      const source = data.snowSource.toLowerCase();
      const grainType = data.snowType.toLowerCase();
      const key = `${temp}___${source}___${grainType}`;

      if (performanceMap[key]) {
        // Weight by the # of skis in that test
        performanceMap[key].sumWeightedScore += data.score * data.total;
        performanceMap[key].sumWeights += data.total;
        performanceMap[key].testCount += 1;
      }
    });

    // Convert to an array of results
    return Object.values(performanceMap).map((item) => {
      if (item.sumWeights > 0) {
        const averageScore = item.sumWeightedScore / item.sumWeights;
        return {
          temperature: item.temperature,
          source: item.source,
          snowType: item.snowType,
          averageScore,
          category: getPerformanceCategory(averageScore),
          count: item.testCount
        };
      } else {
        return {
          temperature: item.temperature,
          source: item.source,
          snowType: item.snowType,
          averageScore: null,
          category: 'Unknown',
          count: 0
        };
      }
    });
  }, [
    heatmapChartData,
    dynamicTemperatureList,
    allSnowCombos,
    getPerformanceCategory
  ]);

  // Grind history
  const grindHistory = ski.grindHistory || [];

  // Filter grind history by selectedSeason
  const filteredGrindHistory = useMemo(() => {
    if (!selectedSeason) return grindHistory;
    return grindHistory.filter((entry) => {
      const grindDateTimestamp = getTimestamp(entry.grindDate);
      if (!grindDateTimestamp) return false;
      const season = getSeason(grindDateTimestamp);
      return season === selectedSeason;
    });
  }, [selectedSeason, grindHistory]);

  // Combine test + grind dates
  const allDates = useMemo(
    () => [
      ...chartData.map((d) => d.testDate),
      ...filteredGrindHistory.map((d) => getTimestamp(d.grindDate)).filter(Boolean)
    ],
    [chartData, filteredGrindHistory]
  );

  const minDate = useMemo(() => {
    if (!allDates.length) return null;
    return Math.min(...allDates);
  }, [allDates]);

  const maxDate = useMemo(() => {
    if (!allDates.length) return null;
    return Math.max(...allDates);
  }, [allDates]);

  // For the performance chart, specifically
  const filteredDates = useMemo(() => {
    if (!selectedSeason) return allDates;
    return [
      ...filteredChartData.map((d) => d.testDate),
      ...filteredGrindHistory.map((d) => getTimestamp(d.grindDate)).filter(Boolean)
    ];
  }, [allDates, filteredChartData, filteredGrindHistory, selectedSeason]);

  const seasonMinDate = useMemo(() => {
    if (!filteredDates.length) return null;
    return Math.min(...filteredDates);
  }, [filteredDates]);

  const seasonMaxDate = useMemo(() => {
    if (!filteredDates.length) return null;
    return Math.max(...filteredDates);
  }, [filteredDates]);

  const maxRank = useMemo(() => {
    if (!chartData.length) return 1;
    return Math.max(...chartData.map((d) => d.rank));
  }, [chartData]);

  // Fetch test data from Firestore
  useEffect(() => {
    const fetchTests = async () => {
      if (!ski.testIds || ski.testIds.length === 0) return;
      const uncached = ski.testIds.filter((id) => !testResultsCache[id]);
      if (uncached.length === 0) return;
      try {
        const fetched = await getSkiTests(user.uid, uncached);
        setTestResultsCache((prev) => {
          const newCache = { ...prev };
          fetched.forEach((test) => {
            newCache[test.id] = test;
          });
          return newCache;
        });
      } catch (err) {
        console.error('Error fetching ski tests:', err);
      }
    };
    fetchTests();
  }, [ski.testIds, testResultsCache, user.uid]);

  // Build chartData from the fetched tests
  useEffect(() => {
    const processData = () => {
      if (!ski.testIds || ski.testIds.length === 0) {
        setChartData([]);
        return;
      }
      // Check if all tests are in the cache
      const allAvailable = ski.testIds.every((id) => testResultsCache[id]);
      if (!allAvailable) return;

      const allTests = ski.testIds
        .map((id) => testResultsCache[id])
        .filter(Boolean);

      const allRankings = [];

      allTests.forEach((test) => {
        // Sort by ascending score
        const sorted = [...test.rankings].sort((a, b) => a.score - b.score);
        let currentRank = 1;
        let i = 0;

        // We'll compute the "relative score" for each position
        // rank=1 => 1.0, rank=total => 0.0
        const computeRelativeScore = (rank, total) => {
          if (total <= 1) return 1; // if you’re the only ski
          return 1 - (rank - 1) / (total - 1);
        };

        while (i < sorted.length) {
          const currentScore = sorted[i].score;
          const tiedGroup = [sorted[i]];
          let j = i + 1;
          while (j < sorted.length && sorted[j].score === currentScore) {
            tiedGroup.push(sorted[j]);
            j++;
          }
          const tiedCount = tiedGroup.length;

          // For each ski in the tie group, same rank, same relative score
          const relScore = computeRelativeScore(currentRank, sorted.length);

          tiedGroup.forEach((r) => {
            allRankings.push({
              ...r,
              rank: currentRank,
              score: relScore, // store the 0–1 relative score
              numberOfSkiesInTest: sorted.length,
              testDate: getTimestamp(test.timestamp),
              location: test.location,
              temp: test.temperature || 0,
              // Structure
              snowType: test.snowCondition?.grainType || '',
              snowSource: test.snowCondition?.source || ''
            });
          });

          currentRank += tiedCount;
          i = j;
        }
      });

      // Filter for this particular ski
      const mySki = allRankings.filter((r) => r.skiId === ski.id);
      mySki.sort((a, b) => a.testDate - b.testDate);

      const finalData = mySki.map((r) => ({
        testDate: r.testDate,
        rank: r.rank,
        score: r.score, // store 0–1
        total: r.numberOfSkiesInTest,
        location: r.location,
        temp: r.temp,
        snowType: r.snowType,
        snowSource: r.snowSource
      }));

      setChartData(finalData);

      // Optionally set the season to the latest test's season
      if (finalData.length > 0) {
        const latestTimestamp = Math.max(...finalData.map((d) => d.testDate));
        const latestSeason = getSeason(latestTimestamp);
        setSelectedSeason(latestSeason);
      }
    };
    processData();
  }, [testResultsCache, ski.testIds, ski.id]);

  // Expand or shrink chart container if user selects a season
  useEffect(() => {
    const updateContainer = () => {
      if (!chartContainerRef.current) return;
      if (selectedSeason) {
        setContainerWidth('100%');
        chartContainerRef.current.scrollLeft = 0;
      } else {
        // For demonstration, if no season is selected, we set width to 200%
        setContainerWidth('200%');
        setTimeout(() => {
          if (chartContainerRef.current) {
            chartContainerRef.current.scrollLeft =
              chartContainerRef.current.scrollWidth;
          }
        }, 400);
      }
    };
    const timer = setTimeout(updateContainer, 0);
    return () => clearTimeout(timer);
  }, [chartData, selectedSeason]);

  // Gather available seasons from chart data + grind history
  const availableSeasons = useMemo(() => {
    const seasons = new Set();

    chartData.forEach((d) => {
      const season = getSeason(d.testDate);
      if (season) seasons.add(season);
    });
    grindHistory.forEach((item) => {
      const ts = getTimestamp(item.grindDate);
      if (ts) {
        const season = getSeason(ts);
        if (season) seasons.add(season);
      }
    });

    // Sort so winter seasons come before summer within the same year
    return Array.from(seasons).sort((a, b) => {
      const getSortKey = (season) => {
        if (season.startsWith('Summer')) {
          const year = parseInt(season.split(' ')[1], 10);
          return `${year}-S`;
        } else {
          const [start] = season.split('-').map(Number);
          return `${start}-W`;
        }
      };
      return getSortKey(a).localeCompare(getSortKey(b));
    });
  }, [chartData, grindHistory]);

  return (
    <div className="px-3 pb-4 animate-fade-down animate-duration-300">
      {/* Ski Info */}
      <div className='grid grid-cols-2 gap-2'>
        <div>
          <label className='text-sm' htmlFor="brand">{t('brand')}</label>
          <p className='font-semibold'>{ski.brand || "--"}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="model">{t('model')}</label>
          <p className='font-semibold'>{ski.model || "--"}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="length">{t('length')}</label>
          <p className='font-semibold'>{ski.length || "--"}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="stiffness">{t('stiffness')}</label>
          <p className='font-semibold'>{ski.stiffness || "--"}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="base">Base</label>
          <p className='font-semibold'>{ski.base || "--"}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="construction">{t('construction')}</label>
          <p className='font-semibold'>{ski.construction || "--"}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="grind_date">{t('grind_date')}</label>
          <p className='font-semibold'>{formatDate(ski.grindDate)}</p>
        </div>
        <div>
          <label className='text-sm' htmlFor="comment">{t('comment')}</label>
          <p className='font-semibold'>{ski.comment || "--"}</p>
        </div>
      </div>

      {/* Performance Chart Section */}
      <div>
        <div className="flex justify-between items-center my-5">
          <h2 className="text-2xl font-semibold">{t('performance')}</h2>
          {ski.testIds && ski.testIds.length > 0 && (
            <SelectSeason
              selectedSeason={selectedSeason}
              handleSeasonChange={handleSeasonChange}
              availableSeasons={availableSeasons}
            />
          )}
        </div>
        {ski.testIds && ski.testIds.length > 0 ? (
          !chartData.length ? (
            <span>{t('test_to_see_performance')}</span>
          ) : (
            <PerformanceChart
              ref={chartContainerRef}
              data={filteredChartData}
              filteredGrindHistory={filteredGrindHistory}
              selectedSeason={selectedSeason}
              minDate={seasonMinDate}
              maxDate={seasonMaxDate}
              maxRank={maxRank}
              CustomTooltip={CustomTooltip}
              containerWidth={containerWidth}
            />
          )
        ) : (
          <i>{t('no_tests')}</i>
        )}
      </div>

      {/* Recommended Conditions Section (Heatmap) */}
      <div>
        <h2 className='text-2xl my-4 font-semibold'>{t('recommended_conditions')}</h2>

        {ski.testIds && ski.testIds.length > 0 ? (
          combinedPerformanceData && combinedPerformanceData.length > 0 ? (
            <div className='flex flex-col'>
              <div className='flex space-x-4'>
                {/* NEW: Toggle for "Current Grind" (heatmap only) */}
                {ski.grindDate && (
                  <div className="mb-4">
                    <label className="inline-flex items-center space-x-2">
                      <input
                        className='h-5 w-5'
                        type="checkbox"
                        checked={showCurrentGrind}
                        onChange={(e) => setShowCurrentGrind(e.target.checked)}
                      />
                      <span>{t('current_grind')}</span>
                    </label>
                  </div>
                )}
                {/* NEW: Toggle for "Current Season" (heatmap only) */}
                <div className="mb-4">
                  <label className="inline-flex items-center space-x-2">
                    <input
                      className='h-5 w-5'
                      type="checkbox"
                      checked={showCurrentSeason}
                      onChange={(e) => setShowCurrentSeason(e.target.checked)}
                    />
                    <span>{t('current_season')}</span>
                  </label>
                </div>
              </div>

              <CombinedConditionsHeatmap
                temperatureList={dynamicTemperatureList}
                allSnowCombos={allSnowCombos}
                combinedPerformanceData={combinedPerformanceData}
                // We still pass chartData so the popup can show test details
                chartData={heatmapChartData}
              />
            </div>
          ) : (
            <i>{t('no_tests')}</i>
          )
        ) : (
          <i>{t('no_tests')}</i>
        )}
      </div>

      {/* Grind History Section */}
      <GrindHistory grindHistory={grindHistory} />

      {/* Action Buttons */}
      <div className="flex p-4 w-full justify-center space-x-10 mt-10 rounded-b bg-background ">
        {ski.archived ? (
          <div className='flex flex-col space-y-2 items-center justify-center'>
            <button
              onClick={onUnarchive}
              className="shadow text-green-500 bg-container hover:bg-green-500 hover:text-btntxt rounded-full p-3 cursor-pointer"
            >
              <RiInboxUnarchiveLine />
            </button>
            <label className='text-sm' htmlFor="unarchive">
              {t('unarchive')}
            </label>
          </div>
        ) : (
          <div className='flex flex-col space-y-2 items-center justify-center'>
            <button
              className="shadow text-orange-500 bg-container hover:bg-orange-500 hover:text-btntxt rounded-full p-3 cursor-pointer"
              onClick={onArchive}
            >
              <RiInboxArchiveLine />
            </button>
            <label className='text-sm' htmlFor="archive">
              {t('archive')}
            </label>
          </div>
        )}

        <div className='flex flex-col space-y-2 items-center justify-center'>
          <button
            className="shadow text-delete bg-container hover:bg-delete hover:text-btntxt rounded-full p-3 cursor-pointer"
            onClick={onDelete}
          >
            <RiDeleteBinLine />
          </button>
          <label className='text-sm' htmlFor="delete">
            {t('delete')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default SkiDetail;
