import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import SkiMatchup from './SkiMatchup/SkiMatchup';
import HelpModal from '../../components/common/HelpModal/HelpModal';
import { TournamentContext } from '../../context/TournamentContext';
import { RiDeleteBinLine, RiQuestionLine } from "react-icons/ri";
import { VscGrabber } from "react-icons/vsc";


const TestingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        selectedSkis,
        currentRound,
        setCurrentRound,
        roundsHistory,
        setRoundsHistory,
        lossPath,
        setLossPath,
        matchScores,
        setMatchScores,
        roundNumber,
        setRoundNumber,
        potentialSwapTarget,
        setPotentialSwapTarget,
        isHelpOpen,
        toggleHelpModal,
        initializeTournament,
        decideTiebreaker,
        resetTournament

    } = useContext(TournamentContext);


    const help = [
        t('test_help1'),
        // Add more tips as needed
    ];


    const totalRounds = Math.ceil(Math.log2(selectedSkis.length));


    const onDragUpdate = (update) => {
        if (!update.destination) {
            setPotentialSwapTarget(null);
            return;
        }

        const sourceMatchId = parseInt(update.source.droppableId.split('-')[1]);
        const destinationMatchId = parseInt(update.destination.droppableId.split('-')[1]);

        // Highlight only if the source and destination match IDs are different
        if (sourceMatchId !== destinationMatchId) {
            const destinationMatch = currentRound.find(match => match.id === destinationMatchId);
            if (destinationMatch && destinationMatch.skis.length === 2) {
                setPotentialSwapTarget(destinationMatch.skis[update.destination.index]);
            } else {
                setPotentialSwapTarget(null);
            }
        } else {
            setPotentialSwapTarget(null);
        }
    };


    const onDragEnd = (result) => {
        if (result.type === 'match') {
            handleTestReorder(result);
        } else {
            // Existing ski drag logic
            setPotentialSwapTarget(null);
            const { source, destination } = result;

            if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
                return;
            }

            const sourceMatchId = parseInt(source.droppableId.split('-')[1]);
            const destinationMatchId = parseInt(destination.droppableId.split('-')[1]);

            const newCurrentRound = [...currentRound];
            const sourceMatch = newCurrentRound.find(match => match.id === sourceMatchId);
            const destinationMatch = newCurrentRound.find(match => match.id === destinationMatchId);

            const movedSkiPair = sourceMatch.skis.splice(source.index, 1)[0];
            const adjustedDestinationIndex = Math.min(destination.index, 1);

            if (sourceMatchId !== destinationMatchId) {
                // Handle movement between different matches
                if (destinationMatch.skis.length === 2) {
                    const swappedSkiPair = destinationMatch.skis.splice(adjustedDestinationIndex, 1, movedSkiPair)[0];
                    sourceMatch.skis.splice(source.index, 0, swappedSkiPair);

                    // Swap scores between the skis
                    const sourceMatchScores = { ...matchScores[sourceMatchId] };
                    const destinationMatchScores = { ...matchScores[destinationMatchId] };

                    // Move score from destination to source
                    sourceMatchScores[swappedSkiPair.id] = destinationMatchScores[swappedSkiPair.id];
                    sourceMatchScores[movedSkiPair.id] = destinationMatchScores[movedSkiPair.id];

                    // Update the destination with the new score
                    destinationMatchScores[movedSkiPair.id] = matchScores[sourceMatchId][movedSkiPair.id];

                    // Update the scores state
                    setMatchScores({
                        ...matchScores,
                        [sourceMatchId]: sourceMatchScores,
                        [destinationMatchId]: destinationMatchScores
                    });
                } else {
                    destinationMatch.skis.splice(adjustedDestinationIndex, 0, movedSkiPair);

                    // Move the score to the destination match
                    const newMatchScores = { ...matchScores };
                    newMatchScores[destinationMatchId] = {
                        ...newMatchScores[destinationMatchId],
                        [movedSkiPair.id]: matchScores[sourceMatchId][movedSkiPair.id]
                    };

                    // Remove the score from the source match
                    delete newMatchScores[sourceMatchId][movedSkiPair.id];

                    setMatchScores(newMatchScores);
                }
            } else {
                // Handle reordering within the same match
                sourceMatch.skis.splice(destination.index, 0, movedSkiPair);
            }

            // Update the current round state
            setCurrentRound(newCurrentRound);
        }
    };


    const handleResetTest = () => {
        const confirmReset = window.confirm(t('reset_test_prompt'));
        if (confirmReset) {
            resetTournament();
            navigate('/skis'); // Navigate to the initial selection page
        }
    };


    useEffect(() => {
        if (selectedSkis.length > 0 && currentRound.length === 0) {
            initializeTournament(selectedSkis);
        } else if (selectedSkis.length === 0) {
            navigate('/skis'); // Redirect if no skis are selected
        }
    }, [selectedSkis, currentRound.length, initializeTournament, navigate]);


    const pairSkisForMatches = (skis) => {
        return skis.reduce((pairs, ski, index) => {
            if (index % 2 === 0) pairs.push({ id: index / 2, skis: [ski], winner: null });
            else pairs[pairs.length - 1].skis.push(ski);
            return pairs;
        }, []);
    };

    const initializeScores = (pairedSkis) => {
        const initialScores = pairedSkis.reduce((scores, match) => {
            scores[match.id] = match.skis.reduce((ms, ski) => {
                ms[ski.id] = 0;
                return ms;
            }, {});
            return scores;
        }, {});
        setMatchScores(initialScores);
        setRoundNumber(1);
    };


    const handleSubmitRound = () => {
        // Validate that each match has one ski with a score of 0
        for (const match of currentRound) {
            const matchScore = matchScores[match.id];
            const [ski1, ski2] = match.skis;

            if (ski2) {
                const ski1Score = matchScore[ski1.id];
                const ski2Score = matchScore[ski2.id];

                if (ski1Score > 0 && ski2Score > 0) {
                    alert('Test ' + (match.id + 1) + ": " + t('test_with_no_0'));
                    return;
                }
            }
        }

        // Proceed with the normal submission process if validation passes
        let nextRoundSkis = [];
        let roundOutcomes = {};

        for (const match of currentRound) {
            const matchScore = matchScores[match.id];
            const [ski1, ski2] = match.skis;

            if (!ski2) {
                nextRoundSkis.push(ski1);
                continue;
            }

            let winner;
            if (matchScore[ski1.id] === matchScore[ski2.id]) {
                winner = decideTiebreaker(ski1, ski2);
                if (winner === null) {
                    return;
                }
            } else {
                winner = matchScore[ski1.id] < matchScore[ski2.id] ? ski1 : ski2;
            }

            const loser = winner === ski1 ? ski2 : ski1;
            const scoreDiff = Math.abs(matchScore[ski1.id] - matchScore[ski2.id]);

            nextRoundSkis.push(winner);
            roundOutcomes[loser.id] = { lostTo: winner.id, scoreDiff };
        }

        setLossPath(prevPath => ({ ...prevPath, ...roundOutcomes }));
        prepareNextRound(nextRoundSkis);
    };

    const prepareNextRound = (nextRoundSkis) => {
        setRoundsHistory(prevHistory => [...prevHistory, { round: currentRound, scores: matchScores, roundNumber }]);

        if (nextRoundSkis.length === 1) {
            navigate('/testing/summary'); // Navigate to summary page
        } else {
            const pairedSkis = pairSkisForMatches(nextRoundSkis);
            setCurrentRound(pairedSkis);
            initializeScores(pairedSkis);
            setRoundNumber(roundNumber + 1);
        }
    };

    const goBackToPreviousRound = () => {
        const history = [...roundsHistory]; // Local copy

        if (history.length === 0) {
            return; // No previous rounds to return to
        }

        const previousRoundData = history[history.length - 1];
        setCurrentRound(previousRoundData.round);
        setMatchScores(previousRoundData.scores);
        setRoundNumber(previousRoundData.roundNumber);

        // Reset the lossPath for skis involved in all subsequent matches
        const newLossPath = { ...lossPath };
        previousRoundData.round.forEach(match => {
            match.skis.forEach(ski => {
                delete newLossPath[ski.id];
            });
        });
        setLossPath(newLossPath);

        // Remove the last item from the history
        setRoundsHistory(history.slice(0, -1));
    };

    const handleTestReorder = (result) => {
        const { source, destination } = result;

        // Do nothing if no destination or the source and destination are the same
        if (!destination || source.index === destination.index) return;

        const reorderedRound = [...currentRound];
        const [movedTest] = reorderedRound.splice(source.index, 1);
        reorderedRound.splice(destination.index, 0, movedTest);

        setCurrentRound(reorderedRound);

        // Optionally adjust history or round number state if needed
    };


    return (
        <>
            <Helmet>
                <title>Ski-Lab: Testing</title>
                <meta name="description" content="Testing skis in a cup format" />
            </Helmet>
            <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={(result) => {
                if (result.type === 'match') handleTestReorder(result);
                else onDragEnd(result);
            }}>
                <div className="py-4 px-2">
                    <div className="flex items-end justify-between">
                        <button
                            type='button'
                            className="justify-self-end p-3 w-fit shadow hover:text-btn bg-container rounded-full"
                            onClick={toggleHelpModal}
                        >
                            <RiQuestionLine />
                        </button>

                        <h2 className="text-xl font-semibold">{t('round')} {roundNumber}/{totalRounds}</h2>

                        <button
                            type="button"
                            className="justify-self-end p-3 w-fit shadow hover:text-btn bg-container text-delete rounded-full"
                            onClick={handleResetTest}
                        >
                            <RiDeleteBinLine />
                        </button>

                    </div>
                    {currentRound.length > 0 && (
                        <Droppable droppableId="round" type="match">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {currentRound.map((match, index) => (
                                        <Draggable key={match.id} draggableId={`match-${match.id}`} index={index}>
                                            {(provided, draggableSnapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`bg-container shadow rounded ${draggableSnapshot.isDragging && 'shadow'}`}
                                                >
                                                    <SkiMatchup
                                                        key={match.id}
                                                        match={match}
                                                        scores={matchScores[match.id] || {}}
                                                        potentialSwapTarget={potentialSwapTarget}
                                                        onScoreChange={(skiId, score) =>
                                                            setMatchScores((prev) => ({
                                                                ...prev,
                                                                [match.id]: { ...prev[match.id], [skiId]: score },
                                                            }))
                                                        }
                                                    />
                                                    <VscGrabber size={25} className='flex w-full justify-self-center bg-sbtn rounded' />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    )}

                    <div className="flex items-end justify-between my-4">
                        {roundNumber > 1 && (
                            <button
                                className="px-5 py-3 bg-sbtn rounded"
                                onClick={goBackToPreviousRound}
                            >
                                {t('go_back')}
                            </button>
                        )}
                        <div className="flex items-center space-x-2">
                            <button
                                className="bg-btn text-btntxt px-5 py-3 rounded shadow hover:opacity-90"
                                onClick={handleSubmitRound}
                            >
                                {t('submit_round')}
                            </button>
                        </div>
                    </div>
                </div>

                <HelpModal isOpen={isHelpOpen} onClose={toggleHelpModal} help={help} />
            </DragDropContext>
        </>
    );
};

export default TestingPage;